.teaser {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &__image {
    border-radius: 50%;
    overflow: hidden;
  }

  &__text {
    margin-left: 10px;
  }
}
